<template>
  <Modal ref="modalAlarmSpecial" v-model="showModal" footer-hide :mask="false" draggable scrollable width="1100px">
    <div class="police-top" slot="header">
      <div :class="['tab-item', curtab == 'powerLineError' ? 'tab-active' : '']" @click="switchTab('powerLineError')"><img :src="curtab == 'powerLineError' ? img.light.police.powerLineErrorActive:img.light.police.powerLineError">{{showLang('alarm.light.powerLineHistory')}}</div>
      <div :class="['tab-item', curtab == 'power' ? 'tab-active' : '']" @click="switchTab('power')"><img :src="curtab == 'power' ? img.light.police.boxPopupActive:img.light.police.boxPopup">{{showLang('com.device.box')}}
      </div>
      <div :class="['tab-item', curtab == 'light' ? 'tab-active' : '']" @click="switchTab('light')"><img :src="curtab == 'light' ? img.light.police.lampPopupActive:img.light.police.lampPopup">{{showLang('com.device.lamp')}}
      </div>
      <div :class="['tab-item', curtab == 'report' ? 'tab-active' : '']" @click="switchTab('report')"><img :src="curtab == 'light' ? img.light.police.lampPopupActive:img.light.police.lampPopup">{{showLang('alarm.report.current')}}
      </div>
      <div :class="['tab-item', curtab == 'offline' ? 'tab-active' : '']" @click="switchTab('offline')"><img :src="curtab == 'offline' ? img.light.police.lampOfflineActive:img.light.police.lampOffline">{{showLang('device.offline')}}</div>

      <!-- <div :class="['tab-item', curtab == 'clock' ? 'tab-active' : '']" @click="switchTab('clock')" v-if="funCodes('dcev')"><img
          :src="curtab == 'clock' ? img.light.police.lampClockActive:img.light.police.lampClock">{{showLang('alarm.type.clock.error')}}</div> -->
    </div>
    <div class="analyse-table">
      <DeviceClockError v-if="curtab=='clock'" />
      <DeviceOffline v-else-if="curtab=='offline'" />
      <StationSimple v-else-if="curtab=='power'" />
      <LightSimple v-else-if="curtab =='light'" />
      <ReportSimple v-else-if="curtab == 'report'" />
      <powerLineError v-else-if="curtab == 'powerLineError'" />
    </div>
  </Modal>
</template>
<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex'
import { sessionData } from '@/plugins/cookie'
import DeviceClockError from './DeviceClockError'
import DeviceOffline from './DeviceOffline'
import StationSimple from './StationSimple'
import LightSimple from './LightSimple'
import ReportSimple from './ReportSimple'
import powerLineError from './powerLineError'
Vue.prototype.$sessionData = { sessionData }
export default {
  name: 'Home',
  components: {
    powerLineError,
    DeviceClockError,
    DeviceOffline,
    StationSimple,
    LightSimple,
    ReportSimple,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: this.value,
      curtab: 'powerLineError',
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'stationAlarmChanged']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
  },
  mounted: function () {
    this.initModalPosition();
  },
  methods: {
    initModalPosition: function () {
      let modal = this.$refs.modalAlarmSpecial.$refs.content;//.querySelector('.ivu-modal-content-drag');
      modal.style.top = `${300}px`;
      modal.style.left = `${400}px`;
    },
    switchTab: function (item) {
      this.curtab = item;
    },
  }
}
</script>
<style scoped>
.tab-item {
  flex: none;
  cursor: pointer;
  background: #ebf0f9;
  border-radius: 19px;
  padding: 0 23px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
  text-align: center;
  margin-right: 30px;
}
.police-top .tab-item img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.tab-active {
  background-color: #e84f4d;
  color: #ffffff;
}
.police-top {
  border-radius: 6px 6px 0px 0px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: -15px;
  line-height: 38px;
  display: flex;
  padding: 12px 15px;
}
.analyse-table {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>