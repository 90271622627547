<template>
  <div class="station-search-container">
    <div class="station-search-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['station-search-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
    </div>
    <div class="station-search-content">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com"></component>
      </template>
    </div>
  </div>
</template>
<script>
import PowerBoxHistory from './powerbox/Index'
import PowerBoxAllHistory from './powerbox/AllList'
import EnergyDeviceIndex from './energydev/Index'
// import EnergyDeviceHistory from './energydev/Single'
// import EnergyDeviceAllHistory from './energydev/AllList'
import LithtHistory from './light/Index'
// import LithtOnRateLog from './light/OnRate'
import OnlineHistory from './online/Index'
import OpenHistory from './control/Index'
// import CurrentCheckHistory from './current/Check'
import CurrentCheckSearch from './current/CheckNew'
// import PowerCheckHistory from './power/Index'
import CommandHistory from './command/Index'
// import EnergyHistory from './energy/Index'
// import CurrentBalanceHistory from './current/Balance'
// import VoltageBalanceHistory from './voltage/Balance'
// import AlarmHistory from './alarm/Index'
// import TransOnlineIndex from './tans/Index'
// import TransDataHistory from './tans/CmdLog'
// import LuxDataHistory from './lux/Index'
import { mapGetters } from 'vuex'
export default {
  name: 'StationSearchIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      tabs: [
        { code: 's1', name: '配电柜历史数据(全)', com: PowerBoxAllHistory ,lang:'history.data.ggd.all'},
        { code: 's15', name: '配电柜历史数据(单)', com: PowerBoxHistory ,lang:'history.data.ggd.one'},
        { code: 's16', name: '能耗数据', com: EnergyDeviceIndex ,lang:'history.data.meter'},
        // { code: 's17', name: '电表历史数据(单)', com: EnergyDeviceHistory ,lang:''},
        {code: 's2', name: '单灯历史数据', com: LithtHistory,lang:'history.data.lamp'},
        { code: 's3', name: '通信状态记录', com: OnlineHistory ,lang:'history.data.comm'},
        // {code: 's14', name: '光照度记录', com: LuxDataHistory,lang:''},
        { code: 's4', name: '开关灯记录', com: OpenHistory ,lang:'history.data.switch'},
        // { code: 's5', name: '电流分析记录', com: CurrentCheckHistory ,lang:'history.data.i.analysis'},
        { code: 's6', name: '电流对比查询', com: CurrentCheckSearch ,lang:'history.data.i.contrast'},
        // {code: 's6', name: '功率分析记录', com: PowerCheckHistory,lang:''},
        { code: 's7', name: '指令下发记录', com: CommandHistory ,lang:'history.data.ins'},
        // {code: 's8', name: '能耗记录', com: EnergyHistory,lang:''},
        // { code: 's9', name: '电压不平衡', com: VoltageBalanceHistory ,lang:'history.data.v.unbalance'},
        // { code: 's10', name: '电流不平衡', com: CurrentBalanceHistory ,lang:''},
        // {code: 's11', name: '亮灯率记录', com: LithtOnRateLog,lang:''},
        // { code: 's12', name: '通信数据记录', com: TransDataHistory ,lang:''},
        // { code: 's13', name: '报警记录', com: AlarmHistory ,lang:'history.data.alarm'},
      ],
      curTab: 's1',
    }
  },
   computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.station-search-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  background-color: white;
}
.station-search-tabs {
  height: 48px;
  flex: none;
  display: flex;
}
.station-search-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
}
.station-search-tab {
  padding: 2px 6px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
  /* border-bottom :2px solid #3880FC; */
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
</style>