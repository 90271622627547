<template>
  <div class="power-config-container">
    <div class="power-config-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['power-config-tab', curTab == item.code ? 'tab-active' : '']"
        @click="switchTab(item.code)">{{showLang(item.lang)}}</div>
    </div>
    <div class="power-config-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com" :args="item.args"
          :stationId="currentStationId" @stationType="obtainStationId"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import CableEdit from './CableEdit'
// import MainEdit from './MainEdit'
// import BranchEdit from './BranchEdit'
// import SwitchEdit from './SwitchEdit'
// import OutputEdit from './OutputEdit'
// import ContactEdit from './ContactEdit'
// import DoorEdit from './DoorEdit'
// import LockEdit from './LockEdit'
// import SmokeEdit from './SmokeEdit'
// import WaterEdit from './WaterEdit'
// import LeakageEdit from './LeakageEdit'
// import VoltageEdit from './VoltageEdit'
// import UnionEdit from './UnionEdit'

import AreaEdit from '@/components/config/light/area/Index'
import PoleEdit from '@/components/config/light/pole/Index'
import LampEdit from '@/components/config/light/lamp/Index'
// import testIndex from './testIndex'
export default {
  name: 'StationIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      curTab: 's2',
      currentStationId: 0,
      tabs: [
        { code: 's1', type: 1, name: '分组管理', lang: "manage.nav.light.group", com: AreaEdit, args: {} },
        { code: 's3', type: 1, name: '灯杆管理', lang: "manage.nav.pole", com: PoleEdit, args: {} },
        { code: 's2', type: 2, name: '单灯列表', lang: "manage.nav.light.list", com: LampEdit, args: {} },
        // { code: 's3', type: 3, name: '测试页面', com: testIndex, args: {} },
      ],
    }
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'deviceTypes', 'productCodes', 'powerBoxTypes']),
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    obtainStationId(id) {
      this.currentStationId = id;
    },
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.power-config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px red; */
  /* padding: 3px 10px; */
}
.power-config-tabs {
  height: 30px;
  flex: none;
  display: flex;
  border-bottom: solid 1px rgba(143, 138, 138, 0.452);
}
.power-config-tab {
  padding: 3px 8px;
  margin: 3px 3px -1px 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(59, 170, 127);
  color: white;
}
.power-config-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
  overflow: hidden;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>